import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import StarIcon from '@mui/icons-material/Star';

const CategoryPage = () => {
  const { categoryId } = useParams();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    axios.get(`https://back.atoneo.com/api/joli-mot-prods?populate=*&filters[joli_mot_categories][idCateg][$eq]=${categoryId}`)
      .then(response => {
        setProducts(response.data.data);
      })
      .catch(error => {
        console.error('There was an error fetching the category products!', error);
      });
  }, [categoryId]);

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h1" sx={{ marginBottom: 4, color: '#c48f8b', textAlign: 'center' }}>
        Produits pour {categoryId.replace('-', ' ')}
      </Typography>
      <Grid container spacing={3}>
        {products.map(product => (
          <Grid item xs={12} md={4} key={product.id}>
            <Card sx={{ position: 'relative' }}>
              <Link to={`/produit/${product.attributes.url}`} style={{ textDecoration: 'none' }}>
                <CardMedia
                  component="img"
                  sx={{ width: '100%', objectFit: 'cover' }}  // Images ajustées à 100% de la largeur
                  image={`https://back.atoneo.com${product.attributes.featuredImage.data.attributes.formats.medium.url}`}
                  alt={product.attributes.Titre}
                />
              </Link>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {product.attributes.Titre}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {product.attributes.DescriptionCourte}
                </Typography>
                <Typography variant="h6" color="primary" sx={{ marginTop: 1 }}>
                  {product.attributes.Prix / 100} €
                </Typography>
                <Button variant="contained" color="primary" sx={{ marginTop: 2 }} component={Link} to={`/produit/${product.attributes.url}`}>
                  Voir Détails
                </Button>
              </CardContent>
              {/* Ajouter un badge en haut à droite */}
              <Chip
                label="Nouveau"
                color="secondary"
                icon={<StarIcon />}
                sx={{ position: 'absolute', top: 10, right: 10 }}
              />
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CategoryPage;
