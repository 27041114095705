import React, { useContext, useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { CartContext } from '../context/CartContext';
import axios from 'axios';

const CheckoutForm = () => {
  const { cart, promoCode, discount } = useContext(CartContext);
  const [{ options }] = usePayPalScriptReducer();

  const [customerInfo, setCustomerInfo] = useState({
    name: '',
    email: '',
    address: '',
    city: '',
    postalCode: '',
    country: '',
  });

  const [orderCreated, setOrderCreated] = useState(false);
  const [cmdIncrement, setCmdIncrement] = useState(null);
  const [paymentCompleted, setPaymentCompleted] = useState(null); // null pour aucune action, true pour succès, false pour échec

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCustomerInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const calculateTotalHT = () => {
    return cart.reduce((sum, item) => sum + (item.price * item.quantity) / 100, 0).toFixed(2);
  };

  const calculateTotalTVA = (totalHT) => {
    const TVA_RATE = 0.2;
    return (totalHT * TVA_RATE).toFixed(2);
  };

  const calculateTotalTTC = (totalHT, totalTVA, discount) => {
    return (parseFloat(totalHT) + parseFloat(totalTVA) - parseFloat(discount)).toFixed(2);
  };

  const totalHT = calculateTotalHT();
  const totalTVA = calculateTotalTVA(totalHT);
  const totalTTC = calculateTotalTTC(totalHT, totalTVA, discount);

  const extractImageName = (imageUrl) => {
    const parts = imageUrl.split('/');
    const imageWithExtension = parts[parts.length - 1];
    const [filename, extension] = imageWithExtension.split('.');
    let baseFilename = filename.startsWith('medium_demo_') ? filename.slice(12) : filename;
    baseFilename = baseFilename.split('_').slice(0, -1).join('-');
    return `${baseFilename}.${extension}`;
  };

  const prepareCardsForAPI = () => {
    return cart.map((item) => ({
      client: customerInfo.name,
      visuel: extractImageName(item.selectedVisual),
      message: item.customText || 'Message par défaut',
      image: 'none',
      gratte: 'coeur',
      margin_top: 90,
      line_height: 45,
      text_margin_top: 1,
      theme: 'Carte à Gratter',
      titre: 'Mon Titre',
      taille_titre: 30,
      couleur_text: '#000000',
      taille_text: 30,
      dateCreation: new Date().toISOString().split('T')[0],
    }));
  };

  const createOrderInAPI = async () => {
    try {
      const userResponse = await axios.get('https://joli-mot.fr/apiEtsy.php', {
        params: {
          cleApi: '34KVHFQSP226YTk4n256QP6rGbqhA5w2ymY7W3pLwdbuc3i75',
          service: 'addUser',
          name: customerInfo.name,
          email: customerInfo.email,
          address: customerInfo.address,
          city: customerInfo.city,
          postal_code: customerInfo.postalCode,
          country: customerInfo.country,
        },
      });

      if (!userResponse.data.success || !userResponse.data.user_id) {
        throw new Error('Erreur de création d\'utilisateur');
      }

      const userId = userResponse.data.user_id;
      const cards = prepareCardsForAPI();

      const orderResponse = await axios.get('https://joli-mot.fr/apiEtsy.php', {
        params: {
          cleApi: '34KVHFQSP226YTk4n256QP6rGbqhA5w2ymY7W3pLwdbuc3i75',
          service: 'addOrder',
          user_id: userId,
          total_ht: totalHT,
          total_tva: totalTVA,
          total_ttc: totalTTC,
          prov: 'site',
          payment_method: 'PayPal',
          cards: JSON.stringify(cards),
        },
      });

      if (!orderResponse.data.success || !orderResponse.data.cmd_increment) {
        throw new Error('Erreur de création de commande');
      }

      setCmdIncrement(orderResponse.data.cmd_increment);
      setOrderCreated(true);
    } catch (error) {
      console.error('Erreur lors de la création de la commande ou des cartes:', error);
    }
  };

  const createOrder = async (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            currency_code: options.currency || 'EUR',
            value: totalTTC,
          },
          custom_id: cmdIncrement, // L'identifiant de la commande
        },
      ],
    });
  };

  const onApprove = (data, actions) => {
    return actions.order.capture().then((details) => {
      setPaymentCompleted(true); // Succès du paiement

      // Appel pour mettre à jour le paiement et le statut de la commande
      axios.get('https://joli-mot.fr/apiEtsy.php', {
        params: {
          cleApi: '34KVHFQSP226YTk4n256QP6rGbqhA5w2ymY7W3pLwdbuc3i75',
          service: 'addPayment', // Appel au service de paiement
          order_id: cmdIncrement, // L'identifiant de la commande
          payment_method: 'PayPal',
          payment_status: 'completed',
          amount: totalTTC,
          transaction_id: details.id, // Transaction ID retourné par PayPal
        },
      })
        .then((response) => {
          if (response.data.success) {
            console.log('Paiement et statut de la commande mis à jour');
          } else {
            console.error('Erreur lors de la mise à jour du paiement et du statut');
          }
        })
        .catch((error) => {
          console.error('Erreur lors de l\'appel API:', error);
        });

    }).catch((error) => {
      setPaymentCompleted(false); // Échec du paiement
    });
  };

  if (cart.length === 0) {
    return <Typography variant="h5">Votre panier est vide.</Typography>;
  }

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Informations client
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="name"
            name="name"
            label="Nom"
            fullWidth
            autoComplete="given-name"
            variant="outlined"
            value={customerInfo.name}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="email"
            name="email"
            label="Email"
            fullWidth
            autoComplete="email"
            variant="outlined"
            value={customerInfo.email}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="address"
            name="address"
            label="Adresse"
            fullWidth
            autoComplete="shipping address-line1"
            variant="outlined"
            value={customerInfo.address}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="city"
            name="city"
            label="Ville"
            fullWidth
            autoComplete="shipping address-level2"
            variant="outlined"
            value={customerInfo.city}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="postalCode"
            name="postalCode"
            label="Code Postal"
            fullWidth
            autoComplete="shipping postal-code"
            variant="outlined"
            value={customerInfo.postalCode}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="country"
            name="country"
            label="Pays"
            fullWidth
            autoComplete="shipping country"
            variant="outlined"
            value={customerInfo.country}
            onChange={handleInputChange}
          />
        </Grid>

        {!orderCreated && (
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={createOrderInAPI}>
              Commander
            </Button>
          </Grid>
        )}
      </Grid>

      <div style={{ marginTop: '20px' }}>
        <Typography variant="h6" gutterBottom>
          Récapitulatif du montant
        </Typography>
        <Typography variant="body1">Total HT : {totalHT} €</Typography>
        <Typography variant="body1">TVA (20%) : {totalTVA} €</Typography>
        <Typography variant="body1">Réduction : {discount} €</Typography>
        <Typography variant="body1">Total TTC : {totalTTC} €</Typography>
      </div>

      {paymentCompleted === true && (
        <div style={{ marginTop: '20px', color: 'green' }}>
          <Typography variant="h5" gutterBottom>
            Merci pour votre achat ! Votre paiement a bien été reçu. Vous recevrez les détails de votre commande par e-mail.
          </Typography>
        </div>
      )}

      {paymentCompleted === false && (
        <div style={{ marginTop: '20px', color: 'red' }}>
          <Typography variant="h5" gutterBottom>
            Une erreur est survenue lors du paiement. Veuillez réessayer.
          </Typography>
        </div>
      )}

      {orderCreated && paymentCompleted === null && (
        <div style={{ marginTop: '20px' }}>
          <Typography variant="h6" gutterBottom>
            Paiement avec PayPal
          </Typography>
          <PayPalButtons createOrder={createOrder} onApprove={onApprove} style={{ layout: "vertical" }} />
        </div>
      )}
    </div>
  );
};

export default CheckoutForm;
