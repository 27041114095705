import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ReactMarkdown from 'react-markdown';

const PageContent = ({ url }) => {
  const [pageContent, setPageContent] = useState('');
  const [pageTitle, setPageTitle] = useState('');
  const [featuredImage, setFeaturedImage] = useState(null);

  // Fetch the content based on the URL
  useEffect(() => {
    axios
      .get(`http://back.atoneo.com/api/joli-mot-pages?filters[url][$eq]=${url}&populate=*`)
      .then(response => {
        const pageData = response.data.data[0].attributes;
        setPageContent(pageData.Contenu);
        setPageTitle(pageData.Titre);

        // Si l'image est présente, la stocke dans l'état
        if (pageData.featuredImage?.data?.attributes?.url) {
          setFeaturedImage(`http://back.atoneo.com${pageData.featuredImage.data.attributes.url}`);
        }
      })
      .catch(error => {
        console.error('Erreur lors de la récupération du contenu de la page !', error);
      });
  }, [url]); // Exécuter lorsque l'URL change

  return (
    <Box sx={{ padding: 3, position: 'relative' }}>
      <Typography variant="h1" sx={{ marginBottom: 4, textAlign: 'center' }}>
        {pageTitle}
      </Typography>

      {featuredImage && (
        <img
          src={featuredImage}
          alt="Featured"
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            width: '150px',
            height: '150px',
            objectFit: 'cover',
            borderRadius: '50%',
            margin: '10px'
          }}
        />
      )}

      {/* Affiche le contenu récupéré */}
      <Box sx={{ marginTop: 5 }}>
        <ReactMarkdown>{pageContent}</ReactMarkdown>
      </Box>
    </Box>
  );
};

export default PageContent;
