import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import StarIcon from '@mui/icons-material/Star';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import PregnantWomanIcon from '@mui/icons-material/PregnantWoman';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import ReactMarkdown from 'react-markdown'; // Import for markdown rendering

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [pageContent, setPageContent] = useState('');
  const [featuredImage, setFeaturedImage] = useState(null); // New state for the featured image
  const VAT_RATE = 0.2; // Exemple de taux de TVA à 20%

  // Fetch products
  useEffect(() => {
    axios.get('https://back.atoneo.com/api/joli-mot-prods?populate=*')
      .then(response => {
        setProducts(response.data.data);
      })
      .catch(error => {
        console.error('There was an error fetching the products!', error);
      });
  }, []);

  // Fetch page content including the featured image
  useEffect(() => {
    axios.get('https://back.atoneo.com/api/joli-mot-pages/1/?populate=*')
      .then(response => {
        const contentData = response.data.data.attributes;
        setPageContent(contentData.Contenu);
        
        // Check if the featuredImage is available and set it in the state
        if (contentData.featuredImage?.data?.attributes?.url) {
          setFeaturedImage(`https://back.atoneo.com${contentData.featuredImage.data.attributes.url}`);
        }
      })
      .catch(error => {
        console.error('There was an error fetching the page content!', error);
      });
  }, []);

  const getLabelProperties = (label) => {
    switch (label) {
      case 'Nouveauté':
        return { backgroundColor: '#A8D5BA', icon: <NewReleasesIcon style={{ color: '#FFFFFF' }} /> }; // Vert pastel
      case 'Promo':
        return { backgroundColor: '#FFCCCB', icon: <LocalOfferIcon style={{ color: '#FFFFFF' }} /> }; // Rose pastel
      case 'Grossesse':
        return { backgroundColor: '#ADD8E6', icon: <PregnantWomanIcon style={{ color: '#FFFFFF' }} /> }; // Bleu pastel
      case 'Faire-part':
        return { backgroundColor: '#FFFACD', icon: <CardGiftcardIcon style={{ color: '#FFFFFF' }} /> }; // Jaune pastel
      case 'Demande témoins':
        return { backgroundColor: '#FFE4E1', icon: <EmojiPeopleIcon style={{ color: '#FFFFFF' }} /> }; // Rose clair pastel
      default:
        return { backgroundColor: '#E0E0E0', icon: <StarIcon style={{ color: '#FFFFFF' }} /> }; // Gris clair
    }
  };

  return (
    <Box sx={{ padding: 3, position: 'relative' }}>
      <Typography variant="h1" sx={{ marginBottom: 4, textAlign: 'center' }}>
        Nos Produits
      </Typography>
      <Grid container spacing={3}>
        {products.map(product => {
          const priceTTC = (product.attributes.Prix / 100) * (1 + VAT_RATE); // Calcul du prix TTC
          
          return (
            <Grid item xs={12} md={4} key={product.id}>
              <Card sx={{ position: 'relative' }}>
                <Link to={`/produit/${product.attributes.url}`} style={{ textDecoration: 'none' }}>
                  <CardMedia
                    component="img"
                    sx={{ width: '100%', objectFit: 'cover' }}
                    image={`https://back.atoneo.com${product.attributes.featuredImage.data.attributes.formats.medium.url}`}
                    alt={product.attributes.Titre}
                  />
                </Link>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {product.attributes.Titre}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                  {product.attributes.DescriptionCourte}
                  </Typography>
                  <Typography variant="h6" color="primary" sx={{ marginTop: 1 }}>
                    {priceTTC.toFixed(2)} € TTC
                  </Typography>
                  <Button variant="contained" color="primary" sx={{ marginTop: 2 }} component={Link} to={`/produit/${product.attributes.url}`}>
                    Voir Détails
                  </Button>
                </CardContent>
                {product.attributes.etiquette && (
                  <Chip
                    label={product.attributes.etiquette}
                    style={{ backgroundColor: getLabelProperties(product.attributes.etiquette).backgroundColor, color: '#FFFFFF' }}
                    icon={getLabelProperties(product.attributes.etiquette).icon}
                    sx={{ position: 'absolute', top: 10, right: 10 }}
                  />
                )}
              </Card>
            </Grid>
          );
        })}
      </Grid>

      {/* Display the content from the page API */}
      <Box sx={{ marginTop: 5 }}>
        <ReactMarkdown>{pageContent}</ReactMarkdown>
      </Box>

      {/* Display the featured image in the top-right corner */}
      {featuredImage && (
        <img
          src={featuredImage}
          alt="Featured"
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            width: '150px',
            height: '150px',
            objectFit: 'cover',
            borderRadius: '50%', // Optional: make the image circular
            margin: '10px' // Optional: add some margin around the image
          }}
        />
      )}
    </Box>
  );
};

export default ProductList;
