import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Typography, Table, TableBody, TableCell, TableHead, TableRow, Select, MenuItem } from '@mui/material';

const OrderManagement = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState(null);

  // Charger les commandes au démarrage, y compris les infos utilisateur
  useEffect(() => {
    axios.get('https://joli-mot.fr/apiEtsy.php?cleApi=34KVHFQSP226YTk4n256QP6rGbqhA5w2ymY7W3pLwdbuc3i75&service=getOrders')
      .then(async (response) => {
        const ordersWithUserDetails = await Promise.all(
          response.data.map(async (order) => {
            const userResponse = await axios.get(`https://joli-mot.fr/apiEtsy.php?cleApi=34KVHFQSP226YTk4n256QP6rGbqhA5w2ymY7W3pLwdbuc3i75&service=getUser&user_id=${order.user_id}`);
            return { ...order, user: userResponse.data };
          })
        );
        // Trier les commandes par date antichronologique (la plus récente d'abord)
        const sortedOrders = ordersWithUserDetails.sort((a, b) => new Date(b.dateCreation) - new Date(a.dateCreation));
        setOrders(sortedOrders);
      })
      .catch((error) => {
        console.error('Erreur lors du chargement des commandes:', error);
      });
  }, []);

  // Mettre à jour le statut d'une commande
  const handleStatusChange = (orderId, newStatus) => {
    axios.get(`https://joli-mot.fr/apiEtsy.php?cleApi=34KVHFQSP226YTk4n256QP6rGbqhA5w2ymY7W3pLwdbuc3i75&service=updateOrderStatus&order_id=${orderId}&status=${newStatus}`)
      .then((response) => {
        // Vérification et nettoyage de la réponse
        const cleanResponse = response.data.split('}{').join('},{');  // Ajout de virgules entre objets concaténés
        const parsedResponse = JSON.parse(`[${cleanResponse}]`); // Conversion en tableau d'objets
  
        // Première réponse: mise à jour du statut
        const statusUpdateResponse = parsedResponse[0];
        if (statusUpdateResponse.success) {
          console.log('Statut mis à jour avec succès:', statusUpdateResponse.message);
          setOrders(orders.map(order => order.id === orderId ? { ...order, status: newStatus } : order));
  
          // Deuxième réponse: détails de la commande
          const orderDetailsResponse = parsedResponse[1];
          if (orderDetailsResponse.success) {
            console.log('Détails de la commande récupérés avec succès:', orderDetailsResponse);
            setSelectedOrder(orderDetailsResponse.data);
          } else {
            console.error('Erreur lors de la récupération des détails de la commande:', orderDetailsResponse.message);
          }
        } else {
          console.error('Erreur lors de la mise à jour du statut:', statusUpdateResponse.message);
        }
      })
      .catch((error) => {
        console.error('Erreur lors de la mise à jour du statut ou de la récupération des détails:', error);
      });
  };
  
  
  

  // Charger les détails d'une commande et paiement
  const handleViewDetails = (orderId) => {
    axios.get(`https://joli-mot.fr/apiEtsy.php?cleApi=34KVHFQSP226YTk4n256QP6rGbqhA5w2ymY7W3pLwdbuc3i75&service=getOrderDetails&order_id=${orderId}`)
      .then((response) => {
        setSelectedOrder(response.data);

        // Récupérer les détails du paiement
        return axios.get(`https://joli-mot.fr/apiEtsy.php?cleApi=34KVHFQSP226YTk4n256QP6rGbqhA5w2ymY7W3pLwdbuc3i75&service=getPayment&order_id=${orderId}`);
      })
      .then((response) => {
        setPaymentDetails(response.data);
      })
      .catch((error) => {
        console.error('Erreur lors du chargement des détails:', error);
      });
  };

  // Fermer les détails de la commande
  const handleCloseDetails = () => {
    setSelectedOrder(null);
    setPaymentDetails(null);
  };

  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h1" gutterBottom>Gestion des Commandes</Typography>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID Commande</TableCell>
            <TableCell>Utilisateur</TableCell>
            <TableCell>Statut</TableCell>
            <TableCell>Total TTC</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map(order => (
            <TableRow key={order.id}>
              <TableCell>{order.cmd_increment}</TableCell>
              <TableCell>
                <Typography variant="body2"><strong>Nom :</strong> {order.user.name}</Typography>
                <Typography variant="body2"><strong>Email :</strong> {order.user.email}</Typography>
                <Typography variant="body2"><strong>Adresse :</strong> {order.user.address}, {order.user.city}, {order.user.postal_code}, {order.user.country}</Typography>
              </TableCell>
              <TableCell>
                <Select
                  value={order.status}
                  onChange={(e) => handleStatusChange(order.id, e.target.value)}
                >
                  <MenuItem value="pending">En attente</MenuItem>
                  <MenuItem value="completed">Terminée</MenuItem>
                  <MenuItem value="cancelled">Annulée</MenuItem>
                  <MenuItem value="aborted">Abandonnée</MenuItem>
                </Select>
              </TableCell>
              <TableCell>{order.total_ttc} €</TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleViewDetails(order.id)}
                >
                  Détails
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {selectedOrder && (
        <div style={{ marginTop: '20px' }}>
          <Typography variant="h6">Détails de la commande {selectedOrder.cmd_increment}</Typography>
          <p><strong>Statut :</strong> {selectedOrder.status}</p>
          <p><strong>Total TTC :</strong> {selectedOrder.total_ttc} €</p>

          {paymentDetails && (
            <>
              <Typography variant="h6" gutterBottom>Détails du paiement</Typography>
              <p><strong>Méthode de paiement :</strong> {paymentDetails.payment_method}</p>
              <p><strong>Montant :</strong> {paymentDetails.amount} €</p>
              <p><strong>Transaction ID :</strong> {paymentDetails.transaction_id}</p>
              <p><strong>Statut du paiement :</strong> {paymentDetails.payment_status}</p>
            </>
          )}

          {selectedOrder.cards && selectedOrder.cards.length > 0 && (
            <>
              <Typography variant="h6" gutterBottom>Détails des Cartes</Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID Carte</TableCell>
                    <TableCell>Client</TableCell>
                    <TableCell>Visuel</TableCell>
                    <TableCell>Message</TableCell>
                    <TableCell>Titre</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedOrder.cards.map((card, index) => (
                    <TableRow key={index}>
                      <TableCell>{card.id}</TableCell> {/* Ajout de l'ID de la carte */}
                      <TableCell>{card.client}</TableCell>
                      <TableCell>{card.visuel}</TableCell>
                      <TableCell>{card.message}</TableCell>
                      <TableCell>{card.titre}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="secondary"
                          href={`/edit-card/${selectedOrder.id}/${card.id}`} // Lien vers la page de modification de la carte
                        >
                          Modifier
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          )}

          <Button variant="contained" color="secondary" onClick={handleCloseDetails} style={{ marginTop: '20px' }}>
            Fermer les détails
          </Button>
        </div>
      )}
    </div>
  );
};

export default OrderManagement;
