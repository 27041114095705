import React, { useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Header from './components/Header';
import ProductList from './components/ProductList';
import ProductDetail from './components/ProductDetail';
import Cart from './components/Cart';
import Checkout from './components/Checkout';
import CategoryPage from './components/CategoryPage';
import ScratchCardPage from './components/ScratchCardPage'; 
import ScratchCardOnlyPage from './components/ScratchCardOnlyPage';
import Breadcrumb from './components/Breadcrumb';
import PageContent from './components/PageContent';
import EditCard from './components/EditCard';
import OrderManagement from './components/OrderManagement'; // Importer le nouveau composant


import { CartProvider } from './context/CartContext';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import './App.css';


// PayPal options
const initialOptions = {
  "client-id": "AWh5b3onMTmuYVIYhNZ8B8d2QX7S7IShRJAwWde70q0lu8KUnOFsV1f85B9bZFFcwy-XjdQi5yECzY2G",
  currency: "EUR",
  intent: "capture",
};

// Composant pour gérer l'affichage du Header en fonction de l'URL
function Layout({ cartIconRef }) {
  const location = useLocation(); // Hook pour obtenir l'URL actuelle

  // Cacher le Header et le Breadcrumb pour la route "/carte-a-gratter/:id"
  const hideHeader = location.pathname.startsWith('/carte-a-gratter/');

  return (
    <>
      {/* Afficher le Header uniquement si on n'est pas sur la route "/carte-a-gratter" */}
      {!hideHeader && <Header cartIconRef={cartIconRef} />}
      <div style={{ padding: '0 20px', minHeight: `calc(100vh - 126px)`, paddingBottom:'50px'}}>
        {!hideHeader && <Breadcrumb />} {/* Cacher le Breadcrumb aussi si hideHeader est vrai */}
        <Routes>
          <Route path="/" element={<ProductList />} />
          <Route path="/produit/:url" element={<ProductDetail cartIconRef={cartIconRef} />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/category/:categoryId" element={<CategoryPage />} />
          <Route path="/carte-a-gratter-digitale-questions-frequentes" element={<PageContent url="carte-a-gratter-digitale-questions-frequentes" />} />
          <Route path="/voir-ma-carte/:id" element={<ScratchCardPage />} />
          <Route path="/gestion-commandes" element={<OrderManagement />} />
          <Route path="/edit-card/:orderId/:cardId" element={<EditCard />} />  
          
          {/* Route sans Header qui affiche uniquement la carte à gratter */}
          <Route path="/carte-a-gratter/:id" element={<ScratchCardOnlyPage />} />
        </Routes>
      </div>
      {!hideHeader && <div className='footer' cartIconRef={cartIconRef} >Joli Ptit Mot -  Cartes numériques à gratter pour les mariages, les naissances, et les baptêmes.</div>}
    </>
  );
}

function App() {
  const cartIconRef = useRef(null);

  return (
    <ThemeProvider theme={theme}>
      <CartProvider>
        <PayPalScriptProvider options={initialOptions}>
          <Router>
            {/* Placer Layout dans le Router pour utiliser useLocation */}
            <Layout cartIconRef={cartIconRef} />
          </Router>
        </PayPalScriptProvider>
      </CartProvider>
    </ThemeProvider>
  );
}

export default App;
